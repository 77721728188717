import './css/style.scss';
import './js/my-ajax.js';

document.addEventListener('DOMContentLoaded', function () {
  const hamburgerBtn = document.getElementById('hamburgerBtn');
  const mobileMenu = document.getElementById('mobileMenu');
  const mobileMenuOverlay = document.getElementById('mobileMenuOverlay');
  const mobileMenuClose = document.getElementById('mobileMenuClose');

  hamburgerBtn.addEventListener('click', function () {
    mobileMenu.style.left = '0';
    mobileMenuOverlay.style.display = 'block';
    setTimeout(() => (mobileMenuOverlay.style.opacity = '1'), 50);
    hamburgerBtn.classList.toggle('active');
  });

  const closeMobileMenu = function () {
    mobileMenu.style.left = '-100%';
    mobileMenuOverlay.style.opacity = '0';
    setTimeout(() => (mobileMenuOverlay.style.display = 'none'), 300);
    hamburgerBtn.classList.toggle('active');
  };

  const mobileMenuLinks = document.querySelectorAll('.nav__link');
  mobileMenuLinks.forEach((link) => {
    link.addEventListener('click', closeMobileMenu);
  });

  mobileMenuOverlay.addEventListener('click', closeMobileMenu);
  mobileMenuClose.addEventListener('click', closeMobileMenu);

  var swiper = new Swiper('.mySwiper', {
    direction: 'vertical',
    slidesPerView: 3,
    spaceBetween: 30,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });

  scrollUp();
});

function scrollUp() {
  const scrollUp = document.getElementById('scroll-up');
  if (window.scrollY >= 400) {
    scrollUp.classList.add('show-scroll');
  } else {
    scrollUp.classList.remove('show-scroll');
  }
}
window.addEventListener('scroll', scrollUp);

const faqQuestions = document.querySelectorAll('.faq__dt');
faqQuestions.forEach((question) => {
  question.addEventListener('click', function () {
    const answer = this.nextElementSibling;
    answer.classList.toggle('active');
    this.classList.toggle('active');
  });
});

document.addEventListener('DOMContentLoaded', function() {
  if (!window.location.pathname.includes('/mediapro/')) {
    return;
  }

  const figures = document.querySelectorAll('.mediapro-banner__figure, .mediapro-client-success__figure, .mediapro-why-invest-videos__figure');
  const images = document.querySelectorAll('.mediapro-banner__image, .mediapro-client-success__image, .mediapro-why-invest-videos__image');

  figures.forEach((figure, index) => {
    const image = images[index];

    figure.addEventListener('mousemove', function(event) {
        const rect = figure.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        const moveX = (x - rect.width / 2) / rect.width * 35;
        const moveY = (y - rect.height / 2) / rect.height * 35;

        const rotateY = (x - rect.width / 2) / rect.width * 35;
        const rotateX = -(y - rect.height / 2) / rect.height * 35;

        image.style.transform = `translate(${moveX}px, ${moveY}px) rotateY(${rotateY}deg) rotateX(${rotateX}deg)`;
    });

    figure.addEventListener('mouseleave', function() {
        image.style.transform = 'translate(0, 0) rotateY(0deg) rotateX(0deg)';
    });
  });
});

document.addEventListener("DOMContentLoaded", function () {
  if (!window.location.pathname.includes('/mediapro/')) {
    return;
  }

  const prevButton = document.querySelector('.mediapro-service-highlights__prev');
  const nextButton = document.querySelector('.mediapro-service-highlights__next');
  const servicesItems = Array.from(document.querySelectorAll('.mediapro-service-highlights__services-item'));

  let currentIndex = 0;

  const updateCarousel = () => {
      const windowWidth = window.innerWidth;

      servicesItems.forEach((item, index) => {
          if (windowWidth <= 767) {
              // No mobile, ajustar a visibilidade e a opacidade
              if (index === currentIndex) {
                  item.style.opacity = '1';
                  item.classList.remove('hidden');
              } else {
                  item.style.opacity = '0.5';
                  item.classList.add('hidden');
              }
              item.style.transform = `translateX(-${currentIndex * 100}%)`;
          } else {
              // Para telas maiores, manter o comportamento padrão
              const visibleItems = windowWidth <= 1024 ? 2 : windowWidth <= 1599 ? 3 : 4;
              if (index >= currentIndex && index < currentIndex + visibleItems) {
                  item.classList.remove('hidden');
                  item.style.opacity = '1';
              } else {
                  item.classList.add('hidden');
              }
              item.style.transform = `translateX(-${currentIndex * 100}%)`;
          }
      });
  };

  prevButton.addEventListener('click', () => {
      if (currentIndex > 0) {
          currentIndex -= 1;
          updateCarousel();
      }
  });

  nextButton.addEventListener('click', () => {
      const windowWidth = window.innerWidth;
      const totalItems = servicesItems.length;
      const maxIndex = windowWidth <= 767 ? totalItems - 1 : totalItems - (windowWidth <= 1024 ? 2 : windowWidth <= 1599 ? 3 : 4);

      if (currentIndex < maxIndex) {
          currentIndex += 1;
          updateCarousel();
      }
  });

  window.addEventListener('resize', updateCarousel);
  updateCarousel();
});
